.form-container {
    margin: 5vh auto !important;
    width: fit-content !important;
    min-width: 30vw !important;
}

#kml-label {
    width: 100%;
}

.slider-form {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%;
    height: 5px; /* Specified height */
    background: lightgrey; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 5px; 
}
.slider-form::-webkit-slider-thumb {
    background-color: #2285D0;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 8px; /* Set a specific slider handle width */
    height: 8px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
    border: unset;
}

.slider-form::-moz-range-thumb {
    width: 8px; /* Set a specific slider handle width */
    height: 8px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    background-color: #2285D0;
    border: unset;
}

input[type="file"] {
    display: none;
}
.file-upload {
    width: 30vw;
    min-width: 300px;
    border: 1px solid lightgray;
    border-radius: 5px;
    display: inline-block;
    padding: 8px 11px;
    height: auto;
    text-align: center;
    background-color: #2285D0;
    color: white !important;
}

.file-upload:hover {
    cursor: pointer;
    background-color: lightgray;
    color: black !important;
}

#container {
    /* background-image: url('../../../assets/images/bg4.jpg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: rgb(0, 0, 0, 0.85); */

}
.standart {
    margin-right: 50px;
    padding-left: unset;
}
.angle {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%;
    height: 5px; /* Specified height */
    background: lightgrey; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 5px; 
}
.angle::-webkit-slider-thumb { 
    background-image: url("../../../assets/images/angle.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    border: unset;
}
.checkbox-form {
    padding-left: 20px;
    padding-right: 10px !important;
    width: fit-content !important;
}