.image-content {
    max-height: 50vh;
    height: fit-content;
    overflow: auto;
    min-width: 360px;
    width: fit-content;
    max-width: 50vw;
    position: relative;
}
.image-content::-webkit-scrollbar {
    /* display: none; */
}
#picture {
    /* height: 100%;
    width: 100%; */
}
.canvas {
    position: absolute;
    left: 0;
    top: 0;
    /* cursor: url('../../../../assets/images/magic-wand.png'), auto; */
}

.polygon {
    width: 100%;
    margin-bottom: 8px !important;
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
}
.blur {
    width: 80px;
    margin: 0 !important;
    height: 32px;
    /* position: absolute !important; */
    font-size: 13px;
    margin-bottom: 8px !important;
}
.thresh {
    width: 80px;
    margin: 0 !important;
    height: 32px;
    /* position: absolute !important; */
    font-size: 13px;
    /* margin-left: auto !important; */
    margin-left: 115px !important;
    margin-bottom: 8px !important;
}
.blur > div, .thresh > div {
    color: black !important;
}
.wand-button, .lasso-button, .clear-button {
    padding: 8px !important;
}
.clear-button {
    margin-left: auto !important;
    margin-right: unset !important;
}
.clear {
    margin: 4px;
    width: 8px;
    height: 8px;
}
.wand-params {
    display: flex;
    position: relative;
}