.price-wrapper {
    overflow: auto;
    padding: 2vw !important;
}
.text {
    white-space: nowrap;
}
.currency {
    width: fit-content !important;
    min-width: fit-content !important;
    float: right !important;
}
.main_header_title {
    vertical-align: -webkit-baseline-middle !important;
}
.price-row {
    background-color: #F9FAFB;
    font-weight: bold;
}
.cell {
    margin: unset !important;
    padding: unset !important;
}
.price-input {
    height: 100% !important;
    width: 100% !important;
    border: none !important;
    border-radius: unset !important;
}
.price-input > input {
    text-align: center !important;
    border: none !important;
    margin: 0 !important;
    height: 100% !important;
}
.price-input > input:hover {
    border: none !important;
    margin: 0 !important;
}

td.center.aligned.cell {
    padding: 0 !important;
}