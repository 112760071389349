.loader-container {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 999 !important;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
    margin: 0 !important;
    opacity: 0.5 !important;
}