.form-reset {
    width: 20vw;
    min-width: 320px;
}
.reset-header {
    margin-top: 5vh !important;
    text-align: center !important;
    font-size: 24px !important;
    font-weight: 300 !important;
    letter-spacing: -.5px !important;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif !important;
}
.ui.card.card-email {
    margin: auto !important;
    width: fit-content !important;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
}

.ui.card.card-send {
    margin: auto !important;
    width: fit-content !important;
    max-width: 350px;
    font-size: 14px;
    line-height: 1.5;
    color: #24292e;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
}
.input-password {
    min-width: 320px;
}