#map-polygon-modal {
    position: absolute;
    height: 60%;
    width: 60%;
    min-width: 300px;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid lightgray;
    border-radius: 5px;
    border-bottom: 1px solid gray;
}

#map-polygon-modal > div {
    margin-top: 0 !important;
}

#map-polygon-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.85);
}
#map-polygon-header {
    height: 50px;
    width: 100%;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

#map-polygon {
    height: 100%;
    width: 100%;
}

#map-polygon-content {
    height: 50vh;
    width: 100%;
    padding: unset;
}