.sign-up-container {
    overflow-y: scroll;
    background-image: url('../../../assets/images/bg3.webp');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.sign-up-card {
    width: fit-content !important;
    height: fit-content !important;
    margin: 10vh auto !important;
}
.sign-up-card-header {
    padding-left: 14px !important;
    padding-top: 14px !important;
}
.sign-up-form {
    min-width: 300px;
    width: 30vw;
}
.sign-up-password-progress {
    padding-left: 1px;
    padding-right: 1px;
    height: 1px;
}
.sign-up-form-sign-up-button {
    float: right;
}

.sign-up-form-sign-in-button {
    float: left;
}