#tabs>.menu {
    background-color: #2285D0;
    margin-bottom: 0;
    text-align: center;
}

#tabs>.menu>.item {
    width: 50%;
    color: darkslategrey;
}

#tabs>.menu>.active.item {
    width: 50%;
    color: white;
    border-color: white;
}

.tab {
    width: 100% !important;
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

.tab-icon {
    position: absolute;
    right: 0;
}

.list>.item {
    padding: 14px !important;
    cursor: pointer;
}

#filter {
    height: -webkit-fill-available;
    height: -moz-available;
    height: stretch;
    border: unset;
}