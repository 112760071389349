#order-container {
}
.order-grid {
  padding: 2vh !important;
}

.column > .order-card {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 100% !important;
}

.order-image-popup {
  padding: 0 !important;
}
