.sign-in-container {
    height: 100%;
    position: relative !important;
    background-image: url('../../../assets/images/bg4.webp');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.sign-in-card {
    width: fit-content !important;
    height: fit-content !important;
    position: absolute !important;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -60%);
}
.sign-in-card-header {
    padding-left: 14px !important;
    padding-top: 14px !important;
}
.form-field-email {
    min-width: 301.04px !important;
}
.form-field-input {
    margin-bottom: 0;
}
.link-wrapper {
    text-align: center;
}
.form-field-checkbox {
    padding-left: 0 !important;
    white-space: nowrap;
}
.sign-in-form-sign-up-button {
    float: left;
}

.sign-in-form-sign-in-button {
    float: right;
}
.sign-in-form {
    min-width: 300px;
    width: 20vw;
}