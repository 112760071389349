body {
  margin: 0;
  font-family: Arial, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root, #root > div, #root > div > div {
  height: inherit;
}

.leaflet-control-attribution .leaflet-control{
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;

}