.buttons {
    bottom: 10px;
    left: 0;
    float: right;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
}

.item>.container {
    padding: 0;
}

.advanced {
    color: black;
    text-align: center;
}