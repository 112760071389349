.card {
    margin-top: 10vh !important;
    margin-bottom: 10vh !important;
    width: -webkit-fill-available;
}
#profile-container {
    margin: 0 5vw;
}
.user-container {
    display: flex;
    padding: 4vh !important;
}
.user-image {
    width: 20vh;
    height: 20vh;
    min-width: 20vh;
    min-height: 20vh;
    object-fit: cover;
}
.user-wrapper {
    margin-left: 4vw;
    width: 100%;
    position: relative;
    height: 20vh;
}
.user-buttons {
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0;
}
.user-name {
    font-family: Calibri, Arial, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
}
.user-email {
    color: grey;
}
.edit {
    float: right;
}

.orders {
    margin-top: 0 !important;
}
.rc-pagination-item:hover {
    border-color: #2186D0 !important;
}
.rc-pagination-item:hover a {
    color: #2186D0 !important;
}
.rc-pagination-item-active {
    font-weight: bold !important;
    background-color: #2186D0 !important;
}
.rc-pagination-item-active a {
    color: white !important;
}
.rc-pagination-options-quick-jumper button, .rc-pagination-options-quick-jumper input {
    color: #2186D0 !important;
    border-color: #2186D0 !important;
}
.rc-pagination-options-quick-jumper button:hover {
    background-color: #2186D0 !important;
    color: white !important;
    border-color: #2186D0 !important;
}
.rc-pagination-item:focus,
.rc-pagination-next:focus,
.rc-pagination-prev:focus,
.rc-pagination-jump-prev:focus,
.rc-pagination-jump-next:focus,
.rc-pagination-options-quick-jumper button:focus {
    outline: none !important;
}