.delete-store:hover {
  color: #dc2828;
}

.edit-store {
  color: gray;
  margin-right: 0 !important;
  flex: none;
}

.edit-store:hover {
  color: black;
}

.edit-save:hover {
  color: #20ba45;
}

.edit-delete:hover {
  color: #dc2828;
}
