#navbar {
    background: #2285D0;
    height: 52.31px !important;
    /*position: sticky;*/
}
#cart {
    color: white;
}
#badge {
    font-size: 8pt;
    position: absolute;
    top: -8px;
    left: -10px;
    z-index: 10;
}
#badge2 {
    font-size: 6pt;
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 10;
}
#logo {
    margin-left: 12px;
    cursor: pointer;
}
#logo > img {
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
}
div.ui.dropdown, div.ui.label {
    color: white;
}
#profile {
    background: transparent;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    padding: unset;
}
#avatar{
    /* background-color: white;
    border: 2px solid white; */
    margin-right: 0;
    width: 2em !important;
    height: 2em !important;
}
#menu {
    margin-top: 5px;
     width: 100%;
}
.order-nav {
    margin-right: 20px !important;
    margin-left: 0 !important;
}
.order-nav:hover {
    color: #2085D0 !important;
}
.right.item {
    width: auto !important;
}
.back {
    color: white !important;
    margin-left: 20px !important;
}
.back:hover {
    color: black !important;
}
.lang {
    min-width: fit-content !important;
    margin-right: 5px !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: white !important;
}
.lang:hover {
    color: black !important;
}