#sidebar {
    width: 380px;
    background-color: white;
    z-index: 1001;
    border-right: 2px solid #2285D0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: .5s;
}
#sidebar::-webkit-scrollbar, #container::-webkit-scrollbar {
    display: none;
}
#map {
    height: calc(100vh - 52.31px);
}
#container {
    /*height: 100%;*/
    width: 100%;
    overflow-y: scroll;
}
#toggleButton {
    background-color: #2285D0;
    height: 50px;
    color: white;
    position: absolute;
    top: 40%;
    left: 360px;
    z-index: 1000;
    transition: .5s left;
    margin-left: 5px;
    padding-right: 2px;
}
#toggleButton > i {
    color: white;
}
.search {
    padding: 18px 10px;
    background: #166bac;
}
.pusher {
    border: none !important;
    border-radius: unset !important;
    margin-left: 120px;
    transition: .5s !important;
}

#new-lead {
    background-color: #2285D0;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: unset;
}
.leaflet-container {
    background-color:rgba(255,0,0,0.0);
}