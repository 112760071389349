.dropzone {
    position: relative;
    background: #FAFAFA;
    min-height: 100px;
    border: 2px dashed #EEEEEE;
    text-align: center;
    outline: unset;
}

.dropzone:hover {
    background: #EEEEEE;
    min-height: 100px;
    border: 2px dashed lightgrey;
    cursor: pointer;
}

.dropzone:focus {
    border: 2px dashed #2085D0;
}

.dropzone > p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    color: #BDBDBD;
}