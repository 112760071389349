  .container {
    width: 100%; /* Width of the outside container */
  }
  
  /* The slider itself */
  .slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 5px; /* Specified height */
    background: lightgrey; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 5px;
  }

  .slider::-webkit-slider-thumb {
    background-image: url('../../../../../../assets/images/cloud.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
    border: unset;
  }
  
  .slider::-moz-range-thumb {
    background-image: url('../../../../../../assets/images/cloud.webp');
    background-repeat: no-repeat;
    background-position: center;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    background-color: transparent;
    border: unset;
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */ 
  #angle-cloud::-webkit-slider-thumb {
    background-image: url('../../../../../../assets/images/cloud.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
    border: unset;
  }
  
  #angle-cloud::-moz-range-thumb {
    /* background-image: url('../../../../../../assets/images/cloud.png'); */
    background-repeat: no-repeat;
    background-position: center;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    background-color: transparent;
    border: unset;
  }

  #angle-angle::-webkit-slider-thumb {
    /* background-image: url('../../../../../../assets/images/angle.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    border: unset;
  }

  #angle-angle::-moz-range-thumb {
    /* background-image: url('../../../../../../assets/images/angle.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    border: unset;
  }
  #angle-sun::-webkit-slider-thumb {
    /* background-image: url('../../../../../../assets/images/angle.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    border: unset;
  }

  #angle-sun::-moz-range-thumb {
    /* background-image: url('../../../../../../assets/images/angle.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    cursor: pointer; /* Cursor on hover */
    border: unset;
  }