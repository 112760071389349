#layers_table {
    padding: 0;
    overflow-y: scroll;
    margin-top: 0;
}

.checkbox {
    padding-left: 10px;
    padding-right: 10px !important;
    width: 30px !important
}

.date-row {
    width: 100px !important;
}

.table_row:hover {
    cursor: pointer;
}

.location,
.info {
    background: transparent !important;
    color: black !important;
    /* float: right; */
}

#list {
    margin: 0;
}

#add_cart {
    color: black;
    background-color: transparent;
}

#add_cart:hover {
    color: #2285D0;
}

#layer_badge {
    background-color: #2285D0;
    padding: 8px 6px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /*font-size: 14px;*/
    line-height: inherit;
    border-radius: 50%;
    height: 35px;
    width: 35px;
}

.preview {
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0 !important;
}

/* .preview:hover {
    -webkit-box-shadow: 0px 0px 2px 3px #4183c4;
    -moz-box-shadow: 0px 0px 2px 3px #4183c4;
    box-shadow: 0px 0px 2px 3px #4183c4;
} */

.item:hover {
    cursor: pointer;
}

#badge3 {
    top: 0;
    right: 0;
    font-size: 6pt;
    font-family: cursive;
    /* position: absolute; */
}

.ui.table:not(.unstackable) tr>th {
    padding-top: 13px !important;
}

@media only screen and (max-width: 767px) {
    .ui.table:not(.unstackable) tbody,
    .ui.table:not(.unstackable) tr,
    .ui.table:not(.unstackable) tr>td,
    .ui.table:not(.unstackable) tr>th {
        width: auto!important;
        display: inline-block!important;
    }
    .ui.table:not(.unstackable) tr {
        width: 100% !important;
    }
    .ui.table {
        margin-top: unset !important;
    }
    .ui.table:not(.unstackable) tr {
        padding: unset !important;
    }
    .date-row {
        width: 100px !important;
    }
    .ui.table:not(.unstackable) tr>th {
        padding-top: 2px !important;
    }
}

.footer-btns {
    display: flex;
    position: -webkit-sticky !important;
    position: sticky !important;
    bottom: 0;
    z-index: 1000;
}

.btn-share,
.btn-store {
    border-radius: 0% !important;
    flex: 1;
    justify-content: center;
    margin: 0 !important;
}